import { useApolloClient } from "@apollo/client/main.cjs";
import { Layout, LegacyCard, Page, Spinner, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { NO_OP_CALLBACK, adminRoutePrefix } from "@smartrr/shared/constants";
import { countriesData } from "@smartrr/shared/data/countries";
import { IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { queryShopifyCustomerPaymentMethods } from "@smartrr/shared/shopifyGraphQL/customer";
import { captureException } from "@smartrr/shared/utils/captureException";
import React, { useEffect, useState } from "react";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";
import {
  CustomerInformationCard,
  IAddressInfoProps,
} from "@vendor-app/app/_sharedComponents/CustomerInformationCard";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { loadCustomer } from "@vendor-app/app/_state/actionCreators/customers";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import {
  BACK_TO_SUBSCRIPTIONS_BUTTON_TITLE,
  CREATE_SUBSCRIPTION_TITLE,
  CustomerSelectorInput,
  NO_CUSTOMER_ADDRESS_SUBTITLE,
  NO_CUSTOMER_SELECTED_SUBTITLE,
  UpdatePaymentMethodStateFunc,
  createValidationSchema,
  initialFormValues,
} from "./libs";
import { SelectSubscriptionProgramSection } from "./libs/components/SelectSubscriptionProgramSection";

export function AdminCreateSubscriptionRouteNew(): React.JSX.Element {
  const [isLoadingSellingPlanGroups] = useState(false);
  const [_isShippingAvailable, setIsShippingAvailable] = useState(false);
  const [keyForResettingSubscription] = useState<number>(1);
  const [_selectedCustomerPaymentMethods, setSelectedCustomerPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [_selectedShopifyPaymentMethodId, setSelectedShopifyPaymentMethodId] = useState<string[]>([]);
  const [selectedCustomerShopifyId, setSelectedCustomerShopifyId] = useState("");
  const [_selectedCustomerCustRelId, setSelectedCustomerCustRelId] = useState("");
  const [_paymentMethodCanBeImportedFromShopify, setPaymentMethodCanBeImportedFromShopify] = useState(false);
  const [customerAddress, setCustomerAddress] = useState<IMailingAddressJson | undefined>(undefined);

  const [sellingPlansLoading, setSellingPlansLoading] = useState(false);
  const [sellingPlanGroups, setSellingPlanGroups] = useState<ISmartrrSellingPlanGroup[]>([]);
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);

  const dispatch = useSmartrrVendorDispatch();
  const client = useApolloClient();
  const { addToast } = useToast();

  const { useValues, setFieldValue } = useTypedForm({
    validationSchema: createValidationSchema(countriesData),
    initialValues: initialFormValues,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: NO_OP_CALLBACK,
  });

  const values = useValues();
  const selectedCountry = countriesData.find(country => country.countryCode === values.country);
  const isLoading = isLoadingSellingPlanGroups;

  const updateShopifyPaymentMethodsState: UpdatePaymentMethodStateFunc = paymentMethods => {
    const firstAvailablePaymentMethod: string[] = paymentMethods
      .filter(paymentMethod => paymentMethod.cardBrand !== "bogus" && !!paymentMethod.shopifyId)
      .splice(0, 1)
      .map(payment => payment.shopifyId!);

    setSelectedCustomerPaymentMethods(paymentMethods);
    setSelectedShopifyPaymentMethodId(firstAvailablePaymentMethod);
  };

  const loadCustomerPaymentMethods = async (
    customerRelationshipId: string,
    customerShopifyId: string,
    custRelId: string
  ) => {
    setPaymentMethodCanBeImportedFromShopify(false);
    setSelectedCustomerShopifyId("");
    setSelectedCustomerCustRelId("");

    const res = await typedFrontendVendorApi.getReq(
      "/customer-relationship/:customerRelationshipId/payment-methods",
      {
        params: {
          customerRelationshipId,
        },
      }
    );

    if (res.type === "success") {
      updateShopifyPaymentMethodsState(res.body as IPaymentMethod[]);
    } else {
      updateShopifyPaymentMethodsState([]);
    }

    if (res.type === "error" || (res.type === "success" && !(res.body as IPaymentMethod[]).length)) {
      const shopifyResponse = await queryShopifyCustomerPaymentMethods(client as any, customerShopifyId);

      if (shopifyResponse.type === "success" && shopifyResponse.body.data.customer) {
        setPaymentMethodCanBeImportedFromShopify(true);
      }
    }

    setSelectedCustomerShopifyId(customerShopifyId);
    setSelectedCustomerCustRelId(custRelId);
  };

  const fetchSellingPlans = async () => {
    setSellingPlansLoading(true);

    const sellingPlanGroupsRes = await typedFrontendVendorApi.getReq("/selling-plan-group", {
      query: {
        cache: "true",
      },
    });

    if (sellingPlanGroupsRes.type === "error") {
      const errorMessage = "Error fetching selling plan groups";
      addToast(errorMessage);
      captureException(errorMessage);
    } else {
      setSellingPlanGroups(sellingPlanGroupsRes.body.sort((a, b) => b.subscriptionCount - a.subscriptionCount));
    }

    setSellingPlansLoading(false);
  };

  useEffect(() => {
    void fetchSellingPlans();
  }, []);

  useEffect(() => {
    if (selectedCustomerShopifyId) {
      void dispatch(loadCustomer(selectedCustomerShopifyId));
    }
  }, [selectedCustomerShopifyId]);

  useEffect(() => {
    if (values === initialFormValues) {
      return;
    }

    const { provinces, zipRequired } = selectedCountry ?? { provinces: [], zipRequired: false };

    if (provinces.length && !values.province) {
      void setFieldValue("province", provinces[0].provinceCode);
    }
    if (!provinces.length) {
      void setFieldValue("province", undefined);
    }
    if (!zipRequired && values.zip) {
      void setFieldValue("zip", undefined);
    }
  }, [values, selectedCountry]);

  const customer = useSmartrrVendorSelector(state => state.customerRelationships.customer);
  const defaultAddressRequiredFields =
    customer?.defaultAddress?.address1 &&
    customer?.defaultAddress?.address2 &&
    customer?.defaultAddress?.city &&
    customer?.defaultAddress?.zip &&
    customer?.defaultAddress?.phone;

  const hasAddress = customerAddress ?? defaultAddressRequiredFields;

  const pageSubtitle = selectedCustomerShopifyId
    ? hasAddress
      ? null
      : NO_CUSTOMER_ADDRESS_SUBTITLE
    : NO_CUSTOMER_SELECTED_SUBTITLE;

  const { isLoading: isCustomerLoading } = useSmartrrVendorSelector(state => state.customerRelationships);

  const addressInfo: IAddressInfoProps | undefined = {
    address: customerAddress ?? customer?.defaultAddress,
    showPhoneNumber: true,
    areActionsDisabled: false,
    onUpdateAddress: setCustomerAddress,
  };

  const onReturn = () => {
    if (!selectedCustomerShopifyId) {
      navigateWithShopInQuery(`${adminRoutePrefix}/subscriptions`);
    }
    setSelectedCustomerShopifyId("");
    setIsOpenPopover(false);
    setCustomerAddress(undefined);
  };

  return (
    <Page
      title={CREATE_SUBSCRIPTION_TITLE}
      backAction={{
        onAction: () => onReturn(),
        content: BACK_TO_SUBSCRIPTIONS_BUTTON_TITLE,
      }}
    >
      <Layout>
        {isLoading ? (
          <Layout.Section>
            <Box mt={4} justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          </Layout.Section>
        ) : (
          <React.Fragment>
            {!selectedCustomerShopifyId || !hasAddress ? (
              <Layout.Section>
                <Box mt={1}>
                  <Text variant="bodyMd" color="subdued" as="p">
                    {pageSubtitle}
                  </Text>
                </Box>
              </Layout.Section>
            ) : (
              <Layout.Section>
                <SelectSubscriptionProgramSection
                  sellingPlansLoading={sellingPlansLoading}
                  sellingPlanGroups={sellingPlanGroups}
                  isOpenPopover={isOpenPopover}
                  setIsOpenPopover={setIsOpenPopover}
                />
              </Layout.Section>
            )}
            <Layout.Section secondary>
              {selectedCustomerShopifyId ? (
                isCustomerLoading || !customer ? (
                  <Spinner />
                ) : (
                  <CustomerInformationCard addressInfo={addressInfo} customerInfo={customer} />
                )
              ) : (
                <LegacyCard>
                  <CustomerSelectorInput
                    key={`customer-${keyForResettingSubscription}`}
                    loadCustomerPaymentMethods={loadCustomerPaymentMethods}
                    setFieldValue={setFieldValue}
                    setIsShippingAvailable={setIsShippingAvailable}
                    updateShopifyPaymentMethodsState={updateShopifyPaymentMethodsState}
                  />
                </LegacyCard>
              )}
            </Layout.Section>
          </React.Fragment>
        )}
      </Layout>
    </Page>
  );
}
